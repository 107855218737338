/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import 'common-style';
@import 'common/assets/styles/colors';
@import 'common/assets/styles/tui-inputs.less';
@import 'common/assets/styles/tui-btn.less';
@import 'common/assets/styles/tui-hint.less';
@import 'common/assets/styles/tui-tabs.less';
@import 'common/assets/styles/tui-svg.less';
@import 'common/assets/styles/tui-toggle.less';

* {
  font-family: 'Inter', sans-serif;
}

:root {
  --tui-font-text-m: var(--tui-font-text-s);
  //--tui-base-03: #e3e3e3;
  --tui-primary: @primary-color;
  --tui-link: @primary-color;
  --tui-primary-hover: @primary-color-hover;
  --tui-primary-active: @primary-color-hover;
  --tui-secondary-hover: @light-green;
  --tui-secondary-active: @light-green-pressed;
  --tui-link-hover: @primary-color;
  --tui-warning-bg: rgba(255, 199, 0, 0.32);

  --readonly-access: @light-blue;
  --full-access: @light-green;
  --custom-access: @light-yellow;

  --tui-radius-m: 6px;

  font-family: 'Inter', sans-serif;

  ::selection {
    background-color: @primary-color;
    color: white;
  }
}

body {
  background: var(--tui-base-01);

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: @common-padding;
  }

  hr {
    background: var(--tui-base-02);
  }
}

//body {
//  --tui-font-text-m: var(--tui-font-text-s);
//  --tui-base-03: #e3e3e3;
//  --tui-primary: @primary-color;
//  --tui-link: @primary-color;
//  --tui-primary-hover: @primary-color-hover;
//  --tui-primary-active: @primary-color-hover;
//  --tui-secondary-hover: @light-green;
//  --tui-secondary-active: @light-green-pressed;
//  --tui-link-hover: @primary-color;
//  font-family: "Inter", sans-serif;
//
//  h2 {
//    font-size: 18px;
//    font-weight: 600;
//    margin: @common-padding;
//  }
//}

tui-svg {
  color: @text-color;
}

tui-data-list {
  button {
    justify-content: flex-start !important;
  }
}

tui-tabs {
  padding-left: 20px;
}

tui-notification {
  a {
    text-decoration: none;
    color: @primary-color;
  }
}

table {
  width: 100%;

  .is-active {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .actions {
    padding: 0 6px 0 0;
    min-width: 44px;
    text-align: center;
  }

  .hover:hover,
  .hover-with-context {
    td {
      background-color: var(--tui-base-02);
    }
  }

  tr {
    th,
    td {
      border: 1px solid var(--tui-base-02) !important;
    }

    th {
      &.th-is-active {
        width: 40px;
      }

      &.th-current-sorting {
        color: var(--tui-text-02);
      }

      .th-sorting {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.context-menu {
  z-index: 999;
  position: absolute;
  width: max-content;

  box-shadow: var(--tui-shadow-dropdown);
  background: var(--tui-elevation-02);
  border-radius: var(--tui-radius-m);
  overflow: hidden;
  border: 1px solid var(--tui-base-04);
  box-sizing: border-box;
  max-width: calc(100% - 8px);
  isolation: isolate;
  pointer-events: auto;
}

.not-found {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  color: @text-color;
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .action-block {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 25px;
  }

  .table-block {
    width: 80%;
  }
}

.header-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 10px;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    h2 {
      margin: 0;
    }
  }
}

.form-item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.form-item_text {
  width: 40%;
}

.form-item_value {
  width: 60%;
}

.icon {
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.object-card-icon-select__item {
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
}

//:focus, :active {
//  outline: none;
//}
//
//a:focus, a:active {
//  outline: none;
//}
//
//nav, footer, header, aside {
//  display: block;
//}
//
//html, body {
//  height: 100%;
//  width: 100%;
//  line-height: 1;
//  font-size: 14px;
//  -ms-text-size-adjust: 100%;
//  -moz-text-size-adjust: 100%;
//  -webkit-text-size-adjust: 100%;
//}
//
//input, button, textarea {
//  font-family: inherit;
//}
//
//input::-ms-clear {
//  display: none;
//}
//
//button {
//  cursor: pointer;
//}
//
//button::-moz-focus-inner {
//  padding: 0;
//  border: 0;
//}
//
//a, a:visited {
//  text-decoration: none;
//}
//
//a:hover {
//  text-decoration: none;
//}
//
//ul li {
//  list-style: none;
//}
//
//img {
//  vertical-align: top;
//}

/*--------------------*/
